<template>
  <div class="batchAddFilesBox">
    <el-upload
    ref="btchUpload"
    class="upload-demo"
    drag
    :action="action"
    :limit="originLimit"
    multiple
    :file-list="fileList"
    :show-file-list="true"
    :auto-upload="false"
    :on-remove="setFileList"
    :on-exceed="handleExceed"
    :http-request="uploadFun"
  >
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">
      <!-- 点击这里或者拖拽文件到这里来附加 -->
      {{$t('c1766')}}
    </div>
    <!-- <div class="el-upload__tip" slot="tip">
      只能上传jpg/png文件，且不超过500kb
    </div> -->
  </el-upload>
  </div>
</template>

<script>
/**
 * 批量新增文件，拖动上传
 */
import * as fileApi from "./api.js";
export default {
  props: {
    // 上传数量限制
    originLimit: {
      type: Number,
      default: 10,
    },
    // 是否正在上传过程中,用于上传过程禁用附加文件按钮
    isUpload: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      action: "/test",
      // 文件上传列表，不能直接访问，直接访问不到
      fileList: [],
      // 一次上传的文件数量，方便禁用附加按钮
      filesNumber: 0,
    }
  },
  methods: {
    /**
     * 手动上传
     * @param {function} callback:上传之后的执行的函数
     */
    uploadSave(){
      this.$refs.btchUpload.submit()

    },
    /**
     * 设置文件上传列表
     * @param {Object} file:更改的文件
     * @param {Array} fileList:文件列表
     */
    setFileList(file,fileList){
      this.fileList=fileList
    },
    /**
     * 文件超出个数限制时的钩子
     * @param {Object} files
     * @param {Object} fileList
     */
    handleExceed(){
      // 您最多只能上传{n}个文件。
      // this.$message.warning('您最多只能上传{n}个文件');
      this.$message.error(this.$i18n.t("c1765",{n:this.originLimit}));
    },
    /**
     * 自定义上传方法
     * @param {Object} fileData:当前上传的文件信息
     */
     uploadFun(fileData={}){
     
      if(this.filesNumber === 0){
        this.$emit('update:isUpload', true)
        
      }
      this.filesNumber++
      let file = fileData.file
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("groupid", "");
      formData.append("libid", "");
      formData.append("parentid", "");
      formData.append("isFromEmail", "");
      fileApi
        .upload(formData)
        .then((res) => {
          this.filesNumber--
          if (res.result) {
            // 存储上传成功的文件
            let fileUpload = Object.assign({id:res.data.fileid},res.data)
            this.fileList.push(fileUpload)
            // 上传成功 label.uploadsuc
            // this.$message.success(
            //   `${res.data.name + " " + this.$i18n.t("label.uploadsuc")}！`
            // );
          } else {
            // 上传失败 label.file.upload.fail
            this.$message.warning(this.$i18n.t("label.file.upload.fail"));
          }
          if(this.filesNumber<=0){
            this.$emit('update:isUpload', false)
            this.$emit('save')
          }
        })
        .catch(() => {
          this.filesNumber--
          if(this.filesNumber<=0){
            this.$emit('update:isUpload', false)
          }
        });
     }
  }
};
</script>

<style lang="scss" scoped>
.batchAddFilesBox{
  display: flex;
  justify-content: center;
  .el-upload__text{
    color: #888888;
  }
 ::v-deep .el-upload-dragger{
    background: #FCFCFC;
  }
}
</style>